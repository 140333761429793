/* You can add global styles to this file, and also import other style files */
@import 'animate.css';

@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;700&display=swap');


* {
    font-family: 'Figtree', sans-serif;
}

body{
    background-color: #E3EDF7;
}

h1 {
    font-size:2em;
    font-weight: 700;;
}

.pad20{
    padding:20px;
}

.r0{
    margin-right: 0;
}

.celeste{
    background-color: #E3EDF7;
}

.moradoDeg{
    background: rgb(69,48,178);
    background: linear-gradient(333deg, rgba(69,48,178,1) 0%, rgba(116,99,228,1) 100%);
}

.rosadoDeg{

    background: rgb(218,110,148);
    background: linear-gradient(333deg, rgba(218,110,148,1) 0%, rgba(218,65,103,1) 100%);
}

.blanco{
    background-color: white;;
}

.colorsvg{
    filter:  grayscale(100%) brightness(200%);
}


.fondoModif {
    background-color: rgba(0, 5, 46, 0.6);
    position: fixed;
    top: 0;
    z-index: 1050;
    min-height: 100vh;
    width: 110%;
    margin-left: 0;
}

.buscador {
    padding: 20px;
}

.pie {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.offcanvas.offcanvas-bottom{
    height: 90%;
    overflow: auto;
    padding-bottom:40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.bodyOffCanvas{
    height: calc(100vh - 130px);
    overflow: auto;
    padding-bottom:40px;
}

.webb{
    background-image: url('assets/images/webb.png');
    background-repeat: repeat;
}

 /* Medium devices (tablets, 768px and up) */
 @media (min-width: 768px) { }

 /* Large devices (desktops, 992px and up) */
@media (min-width: 769px) { }

 /* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }

 /* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }